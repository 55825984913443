import React, { Component } from "react";
import Item1 from "./25.png";
import Item2 from "./q.png";

import "./style.scss";
import Languages from "../../../platform/services/languages";

const {
  my_sint1,
  my_sint2,
  my_sint3,
  my_sint4,
  my_sint5,
  my_sint6,
} = Languages.Translations;

class Sisma extends Component {
  state = {
    data: [
      {
        image: Item1,
      },
      {
        image: Item2,
      },
    ],
  }

  render() {
    const state = this.state;
    return (
      <div className="P-item-details G-flex G-flex-column">
        {state.data.map((item, index) => (
          item.image && (index % 2 === 0) ? <div className="P-details-item G-flex" key={index}>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
            <div className="P-details-description G-flex G-flex-column G-mt-6">
              <h1>MYSINT 100 RM Dual laser</h1>
              <h3>{my_sint1}</h3>
              <h4>{my_sint2}</h4>
            </div>
          </div> : <div className="P-details-item G-flex" key={index}>
            <div className="P-details-description G-flex G-flex-column G-mt-6">
              <h3>{my_sint3}</h3>
              <h4>{my_sint4}</h4>
              <h3>{my_sint5}</h3>
              <h4>{my_sint6}</h4>
            </div>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Sisma;
