export default {
  language_label: "En",
  about_us: "About us",
  contacts: "Contacts",
  price: "Price list",
  technologies: "Technologies",
  all_rights: "Hybrid Laboratory – All Rights Reserved.",
  get_in_touch: "Get in touch",
  meets_art: "Where technology meets art",
  manufacture:
    "We manufacture METAL STRUCTURES from titanium, cobalt chromium and gold. Constructions intended for use in dentistry as well as in healthcare.",
  available1: "NOW AVAILABLE TO ALL DOCTORS, DENTISTS, LABORATORIES",
  available2:
    "NEW QUALITY, NEW SPEED, NEW SERVICES, NEW TECHNOLOGIES UNPRECEDENTED LOW PRICE",
  about_text:
    "We manufacture METAL STRUCTURES from titanium, cobalt chromium and gold. Constructions intended for use in dentistry as well as in healthcare. Titanium articular heads, implants and prostheses, very necessary for people who have problems with the maxillofacial, craniocerebral, and mobile apparatus. Today, the Hybrid laboratory uses the best European-made equipment and high-quality raw materials, thanks to which high quality is guaranteed. Today in the forefront of the development of laser systems, SISMA has been able to extend its know-how to the creation of production solutions for marking, welding, cutting, engraving and additive manufacturing. Being innovative, by vocation, SISMA combines a modern and independent organization of the production with a wealth of highly specialized human resources, thus guaranteeing the highest product quality and promptly responding to market changes and requirements.",
  advantage1: "NEW QUALITY",
  advantage2: "NEW SPEED",
  advantage3: "NEW SERVICES",
  advantage4: "NEW TECHNOLOGIES",
  advantage5: "UNPRECEDENTED LOW PRICE",
  press_button: "For the price list press the button and download it",
  download: "Download the price list",
  address: "Armenia, Yerevan Verin Antarayin st. 0009, 138/3",
  phone: "Phone number",
  email: "Email",
  name: "Name",
  message: "Message",
  contact_text: "Если у вас есть вопросы, дайте нам знать",
  sisma1:
    "LM-D incorporates the Smart Spot technology for a repeatable welding process ensuring a wide depth of field and a stable laser firing already from the first pulses.",
  sisma2: "Desktop welding laser with Smart Stop technology",
  sisma3: "Stereo microscope 10x with optical protection",
  sisma4:
    "Quick setting of welding parameters by means of the multifunctional mouse",
  sisma5: "USB connection / Ethernet",
  sisma6: "Adjustable LED light",
  sisma7: "Machine complete with air and gas nozzles",
  sisma8: "Filter for dust recovering",
  heron1: "Design: compact, lightweight, ergonomic",
  heron2: "Color scanning in 2-3 minutes",
  heron3: " Scanner type: intraoral",
  heron4:
    "The scanner attachment is made of biopolymers, which allows it to be sterilized in an autoclave",
  heron5: "Open system",
  heron6: "ROTATING TIP",
  heron7: "INDIVIDUAL TRAINING",
  heron8: "WITHOUT EXTRA PAYMENTS",
  heron9: "OPTIMIZED PC",
  my_sint1: "Enhanced productivity",
  my_sint2:
    "Thanks to its two laser sources, mysint100 Dual Laser allows for an increase of productivity up to 80%, thus guaranteeing higher flexibility, fast lane delivery and better time management.",
  my_sint3: "Open system",
  my_sint4:
    "Each machining parameter and the entire production strategy are fully customizable according to every specific need.",
  my_sint5: "Perfect laser fusion",
  my_sint6:
    "The uniform and stable inert gas flow grants the best melting stability while keeping its consumption down to a minimum (patented).",
  send_message: "Your message was sent successfully",
  error_message: "Please fill all required fields",
  sale: "100% Free",
  soldiers: "For all those soldiers, who took part in the war",
  titan:
    "Production of titanium articular heads, implants and prostheses, that are very necessary for the people, who have problems with the maxillofacial, craniocerebral, and mobile apparatus",
  buy_heron:
    "Buy HERON IOS scanner and get 10% discount for Hybrid Laboratory services for 1 year",
  heron10: "Software - Windows 10 Pro 64",
  heron11:
    "Hardware - Lenovo ThinkPad P15, Intel Core i7-10850H vPro Processor (2.70GHz), 15.6 FHD (1920x1080) IPS Anti-Glare 500nits, 16.0GB, 1x512GB SSD PCIe, NVIDIA Quadro RTX 3000 6GB GDDR6, HD Camera with Micro Cell Li-PolymerInternal Battery, 94Wh",
  heron12: "Size - length 256 mm, width - 43 mm, height - 43 mm",
  heron13: "File format - STL, PLY, OBJ, DWOS, 3SHAPE and EXOCAD project files",
  heron14: "Color scan, more accurate image",
  heron15: "Scanning in 2-3 minutes",
};
