import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";
import ROUTES from "./platform/constants/routes";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/home";

import ScrollToTop from "react-router-scroll-top";
import Herona from "./pages/courses/herona";
import Sisma from "./pages/courses/sisma";
import MySint from "./pages/courses/my-sint";

class App extends Component {
  componentDidMount() {
    window.routerHistory = createBrowserHistory();
    window.routerHistory.listen(() => window.scrollTo(0, 0));
  }

  render() {
    return (
      <Router history={window.routerHistory}>
        <ScrollToTop>
          <div className="P-main">
            <Header />
            <Switch>
              <Route path={ROUTES.HOME} exact component={Home} />
              <Route path={ROUTES.HERONA} exact component={Herona} />
              <Route path={ROUTES.SISMA} exact component={Sisma} />
              <Route path={ROUTES.MY_SINT} exact component={MySint} />

              <Redirect to={ROUTES.HOME} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
