import React, { Component } from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/logo.png";
import Logo2 from "../../assets/images/logo2.png";

import Languages from "../../platform/services/languages";
import ScrollIntoView from "react-scroll-into-view";
import ROUTES from "../../platform/constants/routes";
import "./style.scss";

const {
  about_us,
  contacts,
  price,
  technologies,
  get_in_touch
} = Languages.Translations;
class Header extends Component {

  render() {
    return (
      <header className="G-flex G-align-center G-justify-between">
        <Link to={ROUTES.HOME} className="P-logo-block">
          <img src={Logo2} alt="Logo" className="P-logo" />
        </Link>
        <div className="P-texts G-flex G-align-center G-justify-between">
          <ScrollIntoView selector="#about">
            <p>{about_us}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#price">
            <p>{price}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#technologies">
            <p>{technologies}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#contacts">
            <p>{contacts}</p>
          </ScrollIntoView>
        </div>
        <div>
          <ScrollIntoView selector="#contacts">
            <button className="G-btn">{get_in_touch}</button>
          </ScrollIntoView>
        </div>
      </header>
    );
  }
}

export default Header;
