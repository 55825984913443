import React, { Component } from "react";
import Item1 from "../../../../assets/images/apranq1.png";
import Item2 from "../../../../assets/images/apranq2.png";
import Item3 from "../../../../assets/images/apranq3.png";

import "animate.css/animate.min.css";
import Languages from "../../../../platform/services/languages";
import "./style.scss";
import { Link } from "react-router-dom";

const {
  technologies
} = Languages.Translations;
class Tutors extends Component {
  tutors = [
    {
      img: Item1,
      name: "MYSINT 100 RM Dual laser",
      animate: 'animate__fadeInLeft',
      path : '/1'
    },
    {
      img: Item2,
      name: "Heron IOS scanner",
      animate: 'animate__fadeInUp',
      path :'/2'
    },
    {
      img: Item3,
      name: "LM-D Manual laser welding system",
      animate: 'animate__fadeInRight',
      path : '/3'
    },
  ];

  render() {
    return (
      <div className="P-home-tutors" id="technologies">
        <h2 className="G-mb-4">{technologies}</h2>
        <div className="P-tutors G-flex G-align-center G-justify-between">
          {this.tutors.map((item, index) => (
            <Link to={item.path} className="P-tutor-item G-flex G-align-center G-justify-center G-flex-column" key={index}>
              <div
                className="P-tutor-img"
                style={{ backgroundImage: `url(${item.img})` }}
              ></div>
              <div>
                <p className="P-tutor-name G-mt-1 G-mb-1">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default Tutors;
