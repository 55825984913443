import React, { Component } from "react";
import Banner from "./components/banner";
import Contact from "./components/contact";
import Counter from "./components/counter";
import Courses from "./components/courses";
import Flag from "./components/flag";
import HomeRegister from "./components/register";
import Tutors from "./components/tutors";

import "./style.scss";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="P-home">
        <div className="G-page">
          <HomeRegister />
          <Courses />
        </div>
        <Banner />
        <div className="G-page">
          <Tutors />
          <Contact />
        </div>
        <Counter />
        <Flag />
      </div>
    );
  }
}

export default Home;
