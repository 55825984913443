import React, { Component } from "react";

import Army from "../../../../assets/images/army.png";
import Languages from "../../../../platform/services/languages";

import "./style.scss";

const {
    sale,
    soldiers,
    titan,
} = Languages.Translations;

class Flag extends Component {

    state = {

    };

    render() {
        return (
            <div className="P-home-flag G-flex">
                <div className="P-sale-text G-flex G-flex-column G-align-center">
                    <h2 className="G-mt-2">{sale}</h2>
                    <h3 className="G-mt-2">{soldiers}</h3>
                    <p className="G-mt-2">{titan}</p>
                    <img className="G-mt-2" src={Army} alt="" />
                </div>
            </div>
        );
    }
}

export default Flag;
