import React, { Component } from "react";
import Item2 from "./4.jpg";
import Item1 from "./1.jpg";

import "./style.scss";
import Languages from "../../../platform/services/languages";

const {
  heron1,
  heron2,
  heron3,
  heron4,
  heron5,
  heron6,
  heron7,
  heron8,
  heron9,
  heron10,
  heron11,
  heron12,
  heron13,
  heron14,
  heron15,
  buy_heron
} = Languages.Translations;

class Herona extends Component {
  state = {
    data: [
      {
        image: Item2,
      },
      {
        image: Item1,
      },
    ],
  }

  render() {
    const state = this.state;
    return (
      <div className="P-item-details G-flex G-flex-column">
        {state.data.map((item, index) => (
          item.image && (index % 2 === 0) ? <div className="P-details-item G-flex" key={index}>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
            <div className="P-details-description G-flex G-flex-column G-mt-6">
              <h1>Herona Ios</h1>
              <h3 className="G-text-center G-bold">{buy_heron}</h3>
              <h3 className="G-no-bold"> &#8226; {heron1} <br /> &#8226; {heron2} <br /> &#8226; {heron3} <br />&#8226; {heron4} <br /> &#8226; {heron5} <br />
              &#8226; {heron6} <br /> &#8226; {heron7} <br />&#8226;  {heron8} <br /> &#8226; {heron9}
              </h3>
            </div>
          </div> : <div className="P-details-item G-flex" key={index}>
            <div className="P-details-description G-flex G-flex-column G-mt-6">
              <h3 className="G-no-bold"> &#8226; {heron10} <br /> &#8226; {heron11} <br /> &#8226; {heron12} <br />&#8226; {heron13} <br /> &#8226; {heron14} <br />
              &#8226; {heron15}
              </h3>
            </div>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Herona;
