import React, { Component } from "react";

import Cover1 from "../../../../assets/images/1.png";
// import Cover2 from "../../../../assets/images/2.png";
import Cover3 from "../../../../assets/images/3.png";
import Cover4 from "../../../../assets/images/4.png";
import Cover5 from "../../../../assets/images/5.png";

import Languages from "../../../../platform/services/languages";

import "./style.scss";

const {
  meets_art,
  manufacture,
  available1,
} = Languages.Translations;

class HomeRegister extends Component {
  slider = null;

  state = {
    activeIndex: 0,
    imageArray: [Cover1, Cover3, Cover4, Cover5],
  };

  componentDidMount() {
    if (this.state.imageArray && this.state.imageArray.length) {
      this.slider = setInterval(() => {
        if (this.state.activeIndex !== this.state.imageArray.length - 1) {
          this.setState({ activeIndex: this.state.activeIndex + 1 });
        } else {
          this.setState({ activeIndex: 0 });
        }
      }, 2000);
    }
  }

  componentWillUnmount() {
    if (this.slider) {
      clearInterval(this.slider);
    }
  }

  setIndex = (index) => {
    this.setState({
      activeIndex: index,
    });
  };


  render() {
    return (
      <div className="P-home-register G-justify-between G-flex G-align-center">
        <div>
          <h1>HYBRID LABORATORY ARMENIA, Հիբրիդ լաբորատորի</h1>
          <h2 className="G-mb-3">
            {meets_art}
          </h2>
          <h4 className="G-mb-2">
            {available1}
          </h4>
          {/* <img src={Simplicity} alt="" /> */}
          <h3 className="G-mb-3">
            {manufacture}
          </h3>
         
          {/* <h4 className="G-mb-2">
          {available2}
          </h4> */}
        </div>
        <div className="P-right">
          <div
            className="P-slider-images"
            style={{
              transform: `translateX(-${this.state.activeIndex * 100}%)`,
            }}
          >
            {this.state.imageArray && this.state.imageArray.length ? (
              this.state.imageArray.map((item, index) => (
                <div className="P-item" key={index}>
                  <div style={{ backgroundImage: `url('${item}')` }}> </div>
                </div>
              ))
            ) : null}
          </div>
        </div>
        {/* <div style={{ backgroundImage: `url(${HomeCover})` }}></div> */}
      </div>
    );
  }
}

export default HomeRegister;
