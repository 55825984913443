import React, { Component } from "react";
import Klor from '../../../../assets/images/klor.svg';

import ScrollAnimation from "react-animate-on-scroll";
import Languages from "../../../../platform/services/languages";

import "./style.scss";

const {
  about_us,
  about_text,
  advantage1,
  advantage2,
  advantage3,
  advantage4,
  advantage5
} = Languages.Translations;
class Courses extends Component {

  render() {
    return (
      <div className="P-home-courses" id="about">
        <h2 className="G-mb-6">{about_us}</h2>
        <h3 className="G-mt-1">{about_text}</h3>
        <div className="P-courses G-flex G-align-center G-justify-center G-flex-column">
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <div className="G-flex G-align-center ">
              <img src={Klor} alt="" />
              <span className="G-ml-2">{advantage1}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight">
            <div className="G-flex G-align-center ">
              <img src={Klor} alt="" />
              <span className="G-ml-2">{advantage2}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <div className="G-flex G-align-center ">
              <img src={Klor} alt="" />
              <span className="G-ml-2">{advantage3}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInRight">
            <div className="G-flex G-align-center ">
              <img src={Klor} alt="" />
              <span className="G-ml-2">{advantage4}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <div className="G-flex G-align-center ">
              <img src={Klor} alt="" />
              <span className="G-ml-2">{advantage5}</span>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default Courses;
