export default {
  language_label: "Ру",
  about_us: "О нас",
  contacts: "Контакты",
  price: "Прайс-листы",
  technologies: "Технологии",
  all_rights: "Hybrid Laboratory – все права защищены.",
  get_in_touch: "Связаться",
  meets_art: "Где технология встречается с искусством",
  manufacture:
    "Мы производим МЕТАЛЛИЧЕСКИЕ КОНСТРУКЦИИ из титана, кобальт хрома и золота. Конструкции предназначены для использования как в стоматологии, так и в здравоохранении.",
  available1: "СЕЙЧАС ДОСТУПНО ВСЕМ ВРАЧАМ, СТОМАТОЛОГАМ, ЛАБОРАТОРИЯМ",
  available2:
    "НОВОЕ КАЧЕСТВО, НОВАЯ СКОРОСТЬ, НОВЫЕ УСЛУГИ, НОВЫЕ ТЕХНОЛОГИИ БЕСПРЕЦЕДЕНТНАЯ НИЗКАЯ ЦЕНА",
  about_text:
    "Мы производим МЕТАЛЛИЧЕСКИЕ КОНСТРУКЦИИ из титана, кобальт хрома и золота. Конструкции предназначены для использования как в стоматологии, так и в здравоохранении. Титановые суставные головки, имплантаты и протезы, очень необходими людям у которых проблеми с челюстно-лицевым, черепно-мозговым, и подвижным аппаратом. Сегодня в лаборатории «Гибрид» используется лучшее оборудование европейского производства и качественное сырье, благодаря чему высокое качество гарантировано. Сегодня, находясь в авангарде разработки лазерных систем, SISMA смогла расширить свои ноу-хау до создания производственных решений для маркировки, сварки, резки, гравировки и аддитивного производства. Будучи новаторской по своему призванию, SISMA сочетает в себе современную и независимую организацию производства с богатством узкоспециализированных человеческих ресурсов, гарантируя тем самым высочайшее качество продукции и оперативно реагируя на изменения и требования рынка.",
  advantage1: "НОВОЕ КАЧЕСТВО",
  advantage2: "НОВАЯ СКОРОСТЬ",
  advantage3: "НОВЫЕ УСЛУГИ",
  advantage4: "НОВЫЕ ТЕХНОЛОГИИ",
  advantage5: "БЕСПРЕЦЕДЕНТНАЯ НИЗКАЯ ЦЕНА",
  press_button: "Для просмотра прайс-листа нажмите кнопку и загрузите",
  download: "Скачать прайс-лист",
  address: "Армения, Ереван, ул. 0009 Верин Антарайн 138/3",
  phone: "Номер телефона",
  email: "Эл. адрес",
  name: "Имя",
  message: "Письмо",
  contact_text: "Если у вас есть вопросы, дайте нам знать",
  sisma1:
    "LM-D включает технологию Smart Spot для повторяемого процесса сварки, обеспечивающего большую глубину резкости и стабильное срабатывание лазера уже с первых импульсов.",
  sisma2: "Настольный сварочный лазер с технологией Smart Stop",
  sisma3: "Стереомикроскоп 10x с оптической защитой",
  sisma4:
    "Быстрая настройка параметров сварки с помощью многофункциональной мыши",
  sisma5: "USB-соединение / Ethernet",
  sisma6: "Регулируемая светодиодная подсветка",
  sisma7: "Машина в комплекте с воздушными и газовыми форсунками",
  sisma8: "Фильтр для улавливания пыли",
  heron1: "Дизайн: компактный, лёгкий, эргономичный",
  heron2: "Цветное сканирование за 2-3 минуты",
  heron3: "Тип сканера: интраоральный",
  heron4:
    "Насадка сканера выполнена из биополимеров, что позволяет стерилизовать её вавтоклаве",
  heron5: "Открытая система",
  heron6: "ВРАЩАЮЩИЙСЯ НАКОНЕЧНИК",
  heron7: "ИНДИВИДУАЛЬНОЕ ОБУЧЕНИЕ",
  heron8: "БЕЗ ЛИШНИХ ПЛАТЕЖЕЙ",
  heron9: "ОПТИМИЗИРОВАННЫЙ ПК",
  my_sint1: "Повышенная продуктивность",
  my_sint2:
    "Благодаря двум лазерным источникам mysint100 Dual Laser позволяет увеличить производительность до 80%, что гарантирует более высокую гибкость, быструю доставку по полосам и лучшее управление временем.",
  my_sint3: "Открытая система",
  my_sint4:
    "Каждый параметр обработки и вся производственная стратегия полностью настраиваются в соответствии с любыми конкретными потребностями.",
  my_sint5: "Идеальный лазерный синтез",
  my_sint6:
    "Равномерный и стабильный поток инертного газа обеспечивает лучшую стабильность плавления при минимальном его расходе.",
  send_message: "Ваше сообщение было успешно отправлено",
  error_message: "Пожалуйста, заполните все обязательные поля",
  sale: "100% бесплатно",
  soldiers: "Для всех солдат, участвовавших в войне",
  titan:
    "Производство титановых суставных головок, имплантатов, протезов для людей у которых проблеми с челюстно-лицевым, черепно-мозговым, и подвижным аппаратом.",
  buy_heron:
    "Купите сканер HERON IOS и получите скидку 10% на услуги гибридной лаборатории на 1 год",
  heron10: "Программное обеспечение - Windows 10 Pro 64",
  heron11:
    "Аппаратное обеспечение - Lenovo ThinkPad P15, процессор Intel Core i7-10850H vPro (2,70 ГГц), 15,6-дюймовый FHD (1920x1080) IPS с антибликовым покрытием 500 нит, 16,0 ГБ, 1x512 ГБ SSD PCIe, NVIDIA Quadro RTX 3000 6 ГБ GDDR6, HD-камера с Micro Cell Li - Полимерный Внутренний аккумулятор, 94Втч",
  heron12: "Размер - длина 256 мм, ширина - 43 мм, высота - 43 мм.",
  heron13: "Формат файла - файлы проектов STL, PLY, OBJ, DWOS, 3SHAPE и EXOCAD",
  heron14: "Цветное сканирование, более точное изображение",
  heron15: "Сканирование за 2-3 минуты",
};
