import React, { Component } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../platform/constants/routes";

import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";

import Arm from "../../assets/images/armenian.png";
import Eng from "../../assets/images/english.png";
import Rus from "../../assets/images/russian.png";

import Languages from "../../platform/services/languages";
import ClickOutside from "../Click-Outside";
import ScrollIntoView from "react-scroll-into-view";
import fb from "../../assets/images/fb.png";
import insta from "../../assets/images/insta.png";
import "./style.scss";

const {
  language_label,
  about_us,
  contacts,
  price,
  technologies,
  all_rights
} = Languages.Translations;

class Footer extends Component {
  state = {
    year: new Date().getFullYear(),
    shown: true
  };

  changeLanguage = shortCode => {
    window.localStorage.setItem('language', shortCode);
    window.location.reload();
  }

  language = () => this.setState({ shown: !this.state.shown });

  closeLanguage = () => this.setState({ shown: true })

  render() {
    const { year } = this.state;
    return (
      <footer className="G-flex G-align-center G-justify-between">
        <Link to={ROUTES.HOME} className="P-logo-block">
          <img src={logo2} alt="logo" className="logo" />
        </Link>
        <div className="G-flex G-align-center G-justify-between P-texts">
          <ScrollIntoView selector="#about">
            <p>{about_us}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#price">
            <p>{price}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#technologies">
            <p>{technologies}</p>
          </ScrollIntoView>
          <ScrollIntoView selector="#contacts">
            <p>{contacts}</p>
          </ScrollIntoView>
          <div onClick={this.language} className='S-language-drop-down'>
            {language_label === 'Հայ' && <img src={Arm} alt="" />}
            {language_label === 'En' && <img src={Eng} alt="" />}
            {language_label === 'Ру' && <img src={Rus} alt="" />}
            <ClickOutside onClickOutside={this.closeLanguage}>
              <div className={`S-dropdown G-flex ${this.state.shown ? 'S-dropdown-hide' : ''}`}>
                {Languages.ShortCode !== 'en' && <span onClick={() => this.changeLanguage('en')}><img src={Eng} alt="" /></span>}
                {Languages.ShortCode !== 'hy' && <span onClick={() => this.changeLanguage('hy')}><img src={Arm} alt="" /></span>}
                {Languages.ShortCode !== 'ru' && <span onClick={() => this.changeLanguage('ru')}><img src={Rus} alt="" /></span>}
              </div>
            </ClickOutside>
          </div>
        </div>
        <div className="G-flex G-justify-between">
          <a href="https://www.facebook.com/hybridlab.am" target="blank" rel="noopener">
            <img src={fb} alt="Facebook" className="icons" />
          </a>
          <a
            href="https://instagram.com/hyebrid.tech?igshid=MzMyNGUyNmU2YQ=="
            target="blank"
            rel="noopener"
          >
            <img src={insta} alt="Instagram" className="icons" />
          </a>
        </div>
        <span>© {year} {all_rights}</span>
      </footer>
    );
  }
}

export default Footer;
