export default {
  language_label: "Հայ",
  about_us: "Մեր մասին",
  contacts: "Հետադարձ կապ",
  price: "Գնացուցակ",
  technologies: "Տեխնոլոգիաներ",
  all_rights: "Hybrid Laboratory - բոլոր իրավունքները պաշտպանված են։",
  get_in_touch: "Կապ մեզ հետ",
  meets_art: "Երբ տեխնոլոգիան ու արվեստը հանդիպում են",
  manufacture:
    "Մենք արտադրում ենք ՄԵՏԱՂԱԿԱՆ ԿՈՆՍՏՐՈՒԿՑԻԱՆԵՐ՝ տիտանից, կոբալտ խրոմից, ոսկուց:Կոնստրուկցիաները նախատեսված են օգտագործելու ինչպես ատամնաբուժության, այնպես էլ առողջապահության ոլորտում:",
  available1:
    "ԱՅԺՄ ԱՐԴԵՆ ՀԱՍԱՆԵԼԻ ԲՈԼՈՐ ԲԺԻՇԿՆԵՐԻՆ, ԱՏԱՄՆԱՏԵԽՆԻԿՆԵՐԻՆ, ԼԱԲՈՐԱՏՈՐԻԱՆԵՐԻՆ",
  available2:
    "ՆՈՐ ՈՐԱԿ, ՆՈՐ ԱՐԱԳՈՒԹՅՈՒՆ, ՆՈՐ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ, ՆՈՐ ՏԵԽՆՈԼՈԳԻԱՆԵՐ ԱՆՆԱԽԱԴԵՊ ՑԱԾՐ ԳԻՆ",
  about_text:
    "Մենք արտադրում ենք ՄԵՏԱՂԱԿԱՆ ԿՈՆՍՏՐՈՒԿՑԻԱՆԵՐ՝ տիտանից, կոբալտ խրոմից, ոսկուց: Կոնստրուկցիաները նախատեսված են օգտագործելու ինչպես ատամնաբուժության, այնպես էլ առողջապահության ոլորտում: Տիտանից հոդային գլխիկներ, իմպլանտներ և պրոթեզներ՝ որոնց արտադրությունը խիստ անհրաժեշտ է վերջույթներ կորցրած, շարժական ապարատի, դիմածնոտային և գանգուղեղային խնդիրներ ունեցող անձանց համար: «Հիբրիդ» լաբորատորիան այսօր կիրառում է եվրոպական արտադրության լավագույն սարքավորումներն ու որակյալ հումք՝ ինչի արդյունքում բարձր որակը երաշխավորված է: Այսօր լազերային համակարգերի զարգացման առաջնագծում SISMA-ն կարողացավ ընդլայնել իր նոու-հաուն մինչև մակնշման, զոդման, կտրման, փորագրման և հավելանյութերի արտադրության արտադրական լուծումների ստեղծումը: Լինելով նորարար, ըստ կոչման, SISMA- ն համատեղում է արտադրության ժամանակակից և անկախ կազմակերպությունը` հսկայական մասնագիտացված մարդկային ռեսուրսների հետ` երաշխավորելով արտադրանքի ամենաբարձր որակը և անհապաղ արձագանքելով շուկայի փոփոխություններին և պահանջներին:",
  advantage1: "ՆՈՐ ՈՐԱԿ",
  advantage2: "ՆՈՐ ԱՐԱԳՈՒԹՅՈՒՆ",
  advantage3: "ՆՈՐ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ",
  advantage4: "ՆՈՐ ՏԵԽՆՈԼՈԳԻԱՆԵՐ",
  advantage5: "ԱՆՆԱԽԱԴԵՊ ՑԱԾՐ ԳԻՆ",
  press_button: "Գնացուցակի համար սեղմեք կոճակը և ներբեռնեք այն",
  download: "Ներբեռնեք գնացուցակը",
  address: "ք. Երևան, Վերին Անտառային փ. 0009, 138/3 շենք",
  phone: "Հեռախոսահամար",
  email: "Էլ. Փոստ",
  name: "Անուն",
  message: "Նամակ",
  contact_text: "Եթե հարց ունեք, տեղյակ պահեք մեզ",
  sisma1:
    "LM-D- ն ընդգրկում է Smart Spot տեխնոլոգիան կրկնվող եռակցման գործընթացի համար՝ ապահովելով դաշտի լայն խորություն և կայուն լազերային կրակում արդեն առաջին իմպուլսներից:",
  sisma2: "Սեղանի եռակցման լազեր Smart Stop տեխնոլոգիայով",
  sisma3: "Ստերեո մանրադիտակ 10x օպտիկական պաշտպանությամբ",
  sisma4: "Եռակցման պարամետրերի արագ տեղադրում բազմաֆունկցիոնալ մկնիկի միջոցով",
  sisma5: "USB կապ / Ethernet",
  sisma6: "Կարգավորվող LED լույս",
  sisma7: "Մեքենան լրացված է օդային և գազային վարդակներով",
  sisma8: "Ֆիլտր փոշու վերականգնման համար",
  heron1: "Կոմպակտ, թեթև դիզայն",
  heron2: "Պտտվող գլխիկ",
  heron3: "Գունավոր սկան 2-3 րոպեի ընթացքում",
  heron4: "Սկաների տիպը՝ ներբերանային",
  heron5:
    "Սկաների կցորդը կազմված է կենսապոլիմերներից, ինչը թույլ է տալիս այն մանրէազերծել ավտոկլավում",
  heron6: "Բաց համակարգ",
  heron7: "Անհատական ուսուցում",
  heron8: "Առանց հավելյալ վճարների",
  heron9: "Նվեր Օպտիմալացված համակարգիչ",
  my_sint1: "Բարձր արտադրողականություն",
  my_sint2:
    "Mysint 100 Dual Laser- ը իր երկու լազերային աղբյուրների շնորհիվ թույլ է տալիս բարձրացնել արտադրողականությունը մինչև 80%, այդպիսով երաշխավորելով ավելի բարձր ճկունություն և ժամանակի ավելի լավ կառավարում:",
  my_sint3: "Բաց համակարգ",
  my_sint4:
    "Մշակման յուրաքանչյուր պարամետր և ամբողջ արտադրական ռազմավարությունը լիովին ընտրովի են՝ ըստ յուրաքանչյուր կարիքի:",
  my_sint5: "Կատարյալ լազերային սինթեզ",
  my_sint6:
    "Միասնական և կայուն իներտ գազի հոսքը տալիս է հալման լավագույն կայունություն `միևնույն ժամանակ դրա սպառումը նվազագույնի հասցնելով:",
  send_message: "Ձեր հաղորդագրությունը հաջողությամբ ուղարկվեց",
  error_message: "Խնդրում ենք լրացնել բոլոր պահանջվող դաշտերը",
  sale: "100% զեղչ",
  soldiers: "պատերազմի բոլոր մասնակից զինծառայողներին",
  titan:
    "Տիտանից հոդային գլխիկների, իմպլանտների և պրոթեզների արտադրություն ՝ վերջույթներ կորցրած, շարժական ապարատի, դիմածնոտային և գանգուղեղային խնդիրներ ունեցող անձանց համար:",
  buy_heron:
    "Ձեռք բերեք HERON IOS սկաները և ստացեք 10% զեղչ Hybrid Laboratory-ի ծառայությունների համար՝ 1 տարի",
  heron10: "Ծրագրային ապահովում - Windows 10 Pro 64",
  heron11:
    "Սարքավորում - Lenovo ThinkPad P15, Intel Core i7-10850H vPro Processor(2.70GHz), 15.6 FHD(1920x1080) IPS Anti-Glare 500nits, 16.0GB, 1x512GB SSD PCIe, NVIDIA Quadro RTX 3000 6GB GDDR6, HD Camera with Microphone, 6 Cell Li-PolymerInternal Battery, 94Wh",
  heron12:
    "Չափս - երկարություն 256 մմ, լայնություն - 43 մմ, բարձրություն - 43 մմ",
  heron13:
    "Ֆայլի ֆորմատ - STL, PLY, OBJ, DWOS, 3SHAPE и EXOCAD",
  heron14: "Գունավոր սկան, առավել ճշգրիտ պատկեր",
  heron15: "Սկանավորում 2-3 րոպեի ընթացքում",
};
