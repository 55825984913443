import React, { Component } from "react";
import Item2 from "./1.jpg";

import Languages from "../../../platform/services/languages";
import "./style.scss";

const {
  sisma1,
  sisma2,
  sisma3,
  sisma4,
  sisma5,
  sisma6,
  sisma7,
  sisma8,
} = Languages.Translations;

class MySint extends Component {
  state = {
    data: [
      {
        image: Item2,
        text: sisma1
      },
      {
        image: Item2,
      },
    ],
  }

  render() {
    const state = this.state;
    return (
      <div className="P-item-details G-flex G-flex-column">
        {state.data.map((item, index) => (
          item.image && (index % 2 === 0) ? <div className="P-details-item G-flex" key={index}>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
            <div className="P-details-description G-flex G-flex-column G-mt-6">
              <h1>Sisma Laser Welder LM-D 210</h1>
              <h3 className="G-no-bold">{item.text}</h3>
            </div>
          </div> : <div className="P-details-item G-flex" key={index}>
            <div className="P-details-description G-mt-6">
              <h3 className="G-no-bold"> &#8226; {sisma2} <br /> &#8226; {sisma3} <br /> &#8226; {sisma3} <br /> &#8226;{sisma4} <br />
              &#8226; {sisma5} <br /> &#8226; {sisma6} <br /> &#8226; {sisma7} <br /> &#8226; {sisma8}
              </h3>
            </div>
            <div className="P-details-image" style={{ backgroundImage: `url('${item.image}')` }}> </div>
          </div>
        ))}
      </div>
    );
  }
}

export default MySint;
