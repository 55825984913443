import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import "./style.scss";

class Counter extends Component {
  render() {
    return (
      <div className="P-home-counter G-flex G-justify-center G-align-center">
        <ScrollAnimation animateIn="animate__fadeInLeft">
          <div>
            <h2>Հավաքագրված միջոցներ</h2>
            <div className="G-mt-3">
              <span>037,729,226,219</span>
              <span>֏</span>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInRight">
          <div className="G-ml-6">
            <h2>Կատարված հատուցումներ</h2>
            <div className="G-mt-3">
              <span>029,802,846,387</span>
              <span>֏</span>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Counter;
