import React, { Component, Fragment } from "react";

import "animate.css/animate.min.css";
import Download from '../../../../assets/images/download.png'
import Languages from "../../../../platform/services/languages";

import "./style.scss";

const {
  price,
  press_button,
  download,
} = Languages.Translations;

class Banner extends Component {
  state = {
    isOpen: false,
    show: false,
    pdf: null
  };

  downloadPdf = () => {
    if (window.localStorage.getItem('language') === 'hy' || !window.localStorage.getItem('language')) {
      this.setState({
        pdf: '/files/price-hy.pdf'
      })
    } else if (window.localStorage.getItem('language') === 'ru') {
      this.setState({
        pdf: '/files/price-ru.pdf'
      })
    } else if (window.localStorage.getItem('language') === 'en') {
      this.setState({
        pdf: '/files/price-eng.pdf'
      })
    }
  }


  render() {
    return (
      <Fragment>
        <div id="price" className="P-home-banner G-relative G-flex G-align-center G-flex-column G-justify-center">
          <h2 className="G-mb-3">
            {price}
          </h2>
          <h3 className="G-mb-3">{press_button}</h3>
          <a href={this.state.pdf} download onClick={this.downloadPdf} className="G-btn G-flex G-align-center">
            <img className="G-mr-2" src={Download} alt="" />
            {download}
          </a>
          <div className="P-background-layer"></div>
        </div>
      </Fragment>
    );
  }
}

export default Banner;
