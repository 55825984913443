import React, { Component } from "react";

import location from "../../../../assets/images/ic_location.png";
import phoneIcon from "../../../../assets/images/ic_phone.png";

import messageIcon from "../../../../assets/images/ic_message.png";
import Languages from "../../../../platform/services/languages";
import "animate.css/animate.min.css";

import "./style.scss";
import Validator from "../../../../platform/services/validator";
import UserController from "../../../../platform/api/user";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const {
  address,
  email,
  name,
  message,
  get_in_touch,
  contacts,
  send_message,
  error_message
} = Languages.Translations;
class Contact extends Component {

  state = {
    name: "",
    email: "",
    message: "",
  };

  submit = async () => {
    const state = this.state;
    if (
      state.message.length > 0 &&
      state.name.length > 0 &&
      Validator.isEmailValid(state.email)
    ) {
      const data = {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
      };
      const res = await UserController.sendInfo(data);
      if (res.success) {
        toast.success(send_message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
        });
        this.setState({
        });
      } else {
        toast.error(error_message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  change = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <div className="P-home-contact" id="contacts">
        <h2>{contacts}</h2>
        <div className="P-contacts G-mt-6 G-flex G-align-center G-justify-between">
          <div className="G-flex G-flex-column">
            <div className="G-flex G-align-center G-mb-3">
              <img src={location} alt="location" />
              <span className="G-ml-2">{address}</span>
            </div>
            <div className="G-flex G-align-center G-mb-3">
              <img src={messageIcon} alt="location" />
              <span className="G-ml-2">info@hyebrid.tech</span>
            </div>
            <div className="G-flex G-align-center">
              <img src={phoneIcon} alt="location" />
              <span className="G-ml-2">+374 33 05 83 05</span>
            </div>
            <div className="G-flex G-flex-column G-mt-3">
              <input type="text" placeholder={name} className="G-mt-3" name="name" autoComplete="off" onChange={this.change} />
              <input type="text" placeholder={email} className="G-mt-3" name="email" autoComplete="off" onChange={this.change} />
              <input type="text" placeholder={message} className="G-mt-3" name="message" autoComplete="off" onChange={this.change} />
              <button onClick={this.submit} className="G-btn G-mt-4">{get_in_touch}</button>
            </div>
          </div>
          <div>
            <iframe title="Hybrid Laboratory" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.626385584458!2d44.5106826156441!3d40.19512737701047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd2323dfe11d%3A0x4f8ac3d5ce0a48b6!2zVmVyaW4gQW50YXJhaW4sIFllcmV2YW4sINCQ0YDQvNC10L3QuNGP!5e0!3m2!1sru!2s!4v1620830347339!5m2!1sru!2s" loading="lazy"></iframe>          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Contact;
