import { connection } from '../services/connection';

export default class UserController {

  static sendInfo = data => {
    return connection({
      url    : 'User/hybrid',
      method : 'Post',
      body   : data
    });
  }
}

